/* mobile.css */

@media (max-width: 768px) {
    .gmail-container {
      flex-direction: column;
    }
  
    .gmail-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      max-width: 80%;
      height: 100%;
      background-color: #fff;
      z-index: 1000;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      overflow-y: auto;
    }
  
    .gmail-sidebar.open {
      transform: translateX(0);
    }
  
    .gmail-content {
      margin-left: 0;
      width: 100%;
    }
  
    .sidebar-toggle {
      display: block;

      font-size: 24px;
      background: none;
      border: none;

      float: left !important;
    }
  
    .compose-button {
      display: none;
    }
  
    .gmail-sidebar .compose-button {
      display: flex;
      margin: 20px;
    }
  
  
    .email-sender,
    .email-subject,
    .email-date {
      width: 100%;
    }
  
    .email-content-area {
      padding: 10px;
    }
  
    /* Adjust other components as needed */
  }
  
  @media (min-width: 769px) {
    .sidebar-toggle {
      display: none;
    }
  
    .gmail-sidebar {
      transform: translateX(0);
      position: relative;
      width: 250px;
    }
  
  }
  
  .svg-inline--fa.fa-bars {
    float: left;
  }