body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


p .p-2, .bg-light, .border, .countdown  {

  background-color: #b0e2ff00 !important;
  width: max-content;
  }

   #borderRemove, .border {
    border: var(--bs-border-width) var(--bs-border-style) #dee2e600 !important;
    
  background-color: #b0e2ff00 !important;
}

.btn.btn-outline-primary.buttonHover {
--bs-btn-hover-bg: #171c25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.faq-answer {
  display: none;
}
.faq-answer.visible {
  display: block;
}


.text-center {
  text-align: center!important;
  padding-top: 40px;
}


svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  height: 17px;
  width: 20px;
  margin-top: 0px;
  color: #4E319F;
}

a.pr-3 {
    padding-left: 15px;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  z-index: 1000;
}

.particlehead {
  background: #abe1fd !important;
  position: absolute;
}

.col-md-12 {
  flex: 0 0 auto;
  width: 100%;
  padding-top: 10px;
}
 

section .air{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
  background-size: 1000px 100px
}
section .air.air1{
  animation: wave 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}
section .air.air2{
  animation: wave2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}
section .air.air3{
  animation: wave 30s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}
section .air.air4{
  animation: wave2 5s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}

@keyframes wave{
  0%{
    background-position-x: 0px; 
  }
  100%{
    background-position-x: 1000px; 
  }
}
@keyframes wave2{
  0%{
    background-position-x: 0px; 
  }
  100%{
    background-position-x: -1000px; 
  }
}

li#unstyled {

  color: #21325E;
}

/* Paper Plane Styles */
.plane-container {
  width: 200px;
  margin: 0 auto;
  padding-top: 100px;
  z-index: 3;
  margin-top: 100px;
  animation: paper-plane-scoping 2s alternate infinite linear;
}

.plane {
  width: 100%;
  height: 60px;
  animation: paper-plane-soaring 4s forwards infinite ease-in-out;
}

@keyframes paper-plane-scoping {
  from { transform: translateY(0); }
  to { transform: translateY(100px); }
}

@keyframes paper-plane-soaring {
  0%, 100% { transform: rotate(0deg); }
  40%, 50% { transform: rotate(15deg); }
  60%, 70% { transform: rotate(-10deg); }
}

/* Cloud Styles */
.clouds1, .clouds2, .clouds3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.cloud {
  position: absolute;
  top: 20%;
  width: 300px;
  right: 0;
  opacity: 1;
  animation: cloud-movement 8s linear forwards infinite;
}

.cloud.front { z-index: 9; }
.cloud.distant, .cloud.background { z-index: 1; }

.cloud.smaller {
  margin-right: 400px;
  width: 100px;
  margin-top: 50px;
}

.cloud.small {
  margin-right: 200px;
  width: 150px;
}

.cloud.big {
  width: 500px;
  margin-top: 50px;
  margin-right: 150px;
}

.cloud.massive {
  width: 600px;
  margin-top: 20px;
}

/* Cloud Animation Durations */
.slow { animation-duration: 9.2s; }
.slower { animation-duration: 11.2s; }
.slowest { animation-duration: 13.5s; }
.super-slow { animation-duration: 20.5s; }

/* Cloud Animation Keyframes */
@keyframes cloud-movement {
  0% {
    opacity: 0.1;
    transform: translateX(300px);
  }
  10% { opacity: 0.7; }
  90% { opacity: 0; }
  100% {
    opacity: 0;
    transform: translateX(-1000px);
  }
}

/* Different Speeds for Different Clouds */
.clouds1 .cloud {
  animation-duration: 10s; /* Slightly different speed */
}

.clouds2 .cloud {
  animation-duration: 12s; /* Slightly different speed */
}

.clouds3 .cloud {
  animation-duration: 14s; /* Slightly different speed */
}




.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg:  #21325E !important;
  --bs-btn-border-color: #21325E !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #26579e8c !important;
  --bs-btn-hover-border-color: #26579e00 !important;

}

#sidebarMenu {
  margin-top: 200px;
 
  background-color: #ffffff00  !important;
}

.marginTop {
  margin-top: 250px;
}

.custom-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin-top: 220px;
  overflow: scroll;
}

.custom-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red;
}
.nav-item.btn.btn-light {
  --bs-border-radius: 0;
}


  .get-in-touch  {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 3.2em;
       color: #5543ca;
      background: #5543ca;
      background: -moz-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
      background: -webkit-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
      background: linear-gradient(to right,#f4524d  0%,#5543ca  100%) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
      font-weight: 500 !important;
  }
  .btn-dark {
    margin-top: 10px;
  }

  .synonym-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .contact-form .form-field {
    position: relative;
    margin: 25px 0;
  }
  .contact-form .input-text {
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 2px 0;
    border-color: #5543ca;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
  .contact-form .input-text:focus {
    outline: none;
  }
  .contact-form .input-text:focus + .label,
  .contact-form .input-text.not-empty + .label {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
  }
  .contact-form .label {
    position: absolute;
    left: 20px;
    bottom: 40px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #5543ca;
    cursor: text;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, 
    -webkit-transform .2s ease-in-out;
  }

  .contact-form .blocks {
    bottom: 310px;
  }
  .contact-form .submit-btn {
    display: inline-block;
    background-color: #000;
     background-image: linear-gradient(125deg,#a72879,#064497);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    width:200px;
    cursor: pointer;
  }
  
  body{margin-top:20px;}
  .pricing-content{}
 .single-pricing{
 background:#fff;
 padding:40px 20px;
 border-radius:5px;
 position:relative;
 z-index:2;
 border:1px solid #eee;
 box-shadow: 0 10px 40px -10px rgba(0,64,128,.09);
 transition:0.3s;
 min-height: 555px;
 }
 @media only screen and (max-width:480px) { 
 .single-pricing {margin-bottom:30px;}
 .site-navbar .site-navigation .site-menu > li > a {
  font-size: 13px !important;
}

.site-navbar {
height: 212px !important;
}
 }
 .single-pricing:hover{
 box-shadow:0px 60px 60px rgba(0,0,0,0.1);
 z-index:100;
 transform: translate(0, -10px);
 }
 .price-label {
     color: #fff;
   background: #ffaa17;
   font-size: 16px;
   width: 100px;
   margin-bottom: 15px;
   display: block;
   -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
   clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
   margin-left: -20px;
   position: absolute;
 }
 .price-head h2 {
   font-weight: 600;
   margin-bottom: 0px;
   text-transform: capitalize;
   font-size: 26px;
 }
 .price-head span {
   display: inline-block;
   background:#ffaa17;
   width: 6px;
   height: 6px;
   border-radius: 30px;
   margin-bottom: 20px;
   margin-top: 15px;
 }
 .price {
   font-weight: 500;
   font-size: 50px;
   margin-bottom: 0px;
 }
 .single-pricing{}
 .single-pricing h5 {
   font-size: 14px;
   margin-bottom: 0px;
   text-transform: uppercase;
 }
 .single-pricing ul{
 list-style: none;
 margin-bottom: 20px;
 margin-top: 30px;
 }
 
 .single-pricing ul li{line-height: 35px;}
 .single-pricing a {
 background:none;
 border: 2px solid #ffaa17;
 border-radius: 5000px;
 color: #ffaa17;
 display: inline-block;
 font-size: 16px;
 overflow: hidden;
 padding:10px 45px;
 text-transform: capitalize;
 transition: all 0.3s ease 0s;
 }
 .single-pricing a:hover, .single-pricing a:focus{
 background:#ffaa17;
 color:#fff;
 border: 2px solid #ffaa17;
 }
 .single-pricing-white{background: #232434}
 .single-pricing-white ul li{color:#fff;}
 .single-pricing-white h2{color:#fff;}
 .single-pricing-white h1{color:#fff;}
 .single-pricing-white h5{color:#fff;}

 #pricing {
  margin-top: 230px;
 }

 .container.mt-5 {
  margin-top: 9rem!important;
  margin-left: 1rem;
}

.floatR {
  float: right;
}

li.nav-item .svg-inline--fa {
  float: left;
  margin-top: 5px;
} 

/* FAQ Container */
.faq-container {
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin-top: 135px;
  background-color: #fdfdfd;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* FAQ Title */
.faq-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #B0E2FF;
  padding-bottom: 10px;
}

/* FAQ List */
.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-list li {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.faq-list li:hover {
  border-color: #d1d1d1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* FAQ List Strong */
.faq-list strong {
  font-size: 1.4em;
  color: #444;
  display: block;
  margin-bottom: 8px;
}

/* FAQ List Paragraph */
.faq-list p {
  margin: 0 0 10px 20px;
  line-height: 1.6;
  color: #666;
}

/* Nested FAQ List */
.faq-list ul {
  list-style-type: disc;
  padding-left: 20px;
}

.faq-list ul li {
  margin-bottom: 8px;
}

.faq-list ul li p {
  margin-left: 0;
}


.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.register-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 639px;
  width: 100%;
  padding: 20px;
  margin-top: 200px;
}

.site-section-heading {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}



.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.popup-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}

.popup-message button {
  background: none;
  border: none;
  color: #721c24;
  font-size: 16px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.roadmap-container {
  font-family: 'Roboto', sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin-top: 150px;
  color: #333;
}

.phase {
  margin-bottom: 40px;
}

h2 {
  border-bottom: 2px solid #B0E2FF;
  padding-bottom: 10px;
}



ul > li > strong {
  color: #0056b3;
  font-size: 1.2em;
}

ul > li > ul {
  padding-left: 20px;
  margin-top: 10px;
}

ul > li > ul > li {
  margin-bottom: 10px;
  font-size: 1em;
}

ul > li > ul > li > strong {
  color: #333;
}

.phase {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
}

ul > li > ul > li {
  color: #555;
}

#centeringClass {
  display: block; margin: 0 auto;
      font-weight: 900;
    padding: 20px;

}

#centeringClass:hover {
  background-color: black !important;
}


div .faq-container {
  margin-top: 200px !important;
}


.form-select-sm {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
}

.btn-sm {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
}

.dropdown-menu {
  font-size: 0.875rem;
}

.accordion-button {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #007bff;
  background-color: #e9ecef;
}

.gap-2 {
  gap: 0.5rem;
}

.btn-group.floatR {
  margin-top: 10px;
}



.centered {
  margin-top: 20px;
  text-align: center;
}

/* CSS for the mood and tone selection */
.table.mood-tone-table tbody tr button {
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  border: none;
  background: none;
  padding: 10px;
  text-align: left;
}

.table.mood-tone-table tbody tr button:hover {
  background-color: #f0f0f0; /* Light grey for hover effect */
}

.table.mood-tone-table tbody tr button.selected {
  color: white;
  background: #5543ca;
  background: -moz-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
  background: -webkit-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
  background: linear-gradient(to right,#f4524d  0%,#5543ca  100%) !important;
  

}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.priceTD {
  font-size: 15px !important;
  font-weight: 700;
}


#message {
  /* Remove fixed min-height if you want it to start smaller */
  min-height: 150px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 16px;
  transition: border-color 0.3s, height 0.2s;
  overflow: auto !important; /* Change from overflow-y: auto to hidden */
  height: auto !important; /* Ensure height is auto */
  resize: none; /* Prevent manual resizing */
  margin-top: 10px;

}



#message::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 50px; /* Add margin to make the image smaller all around */
  background-image: url('/public/images/img_51.png');
  background-size: contain; /* Ensure the image resizes to fit within the element */
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* Ensure the background image stays behind the content */
}


.privacy-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 170px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.privacy-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.privacy-text {
  font-size: 1.2em;
  line-height: 1.8;
  color: #333;
  margin-bottom: 15px;
}

.privacy-section {
  margin-bottom: 30px;
}

.privacy-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.privacy-card:hover {
  transform: translateY(-5px);
}

.privacy-section-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.privacy-icon {
  font-size: 2em;
  color: #007BFF;
  margin-bottom: 10px;
}

.faq-container .accordion-item {
  margin-bottom: 20px;
}

.faq-container .accordion-body {
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 20px;
}

.faq-container .accordion-header {
  font-size: 1.2rem;
}

.faq-container .accordion-button {
  padding: 20px;
}

.faq-container .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faq-container .faq-accordion {
  margin-bottom: 20px;
}

/* Additional styles for better readability */
.faq-container h1.faq-title {
  font-size: 2rem;
  margin-bottom: 40px;
}

.faq-container .accordion-body {

  border-radius: 5px;
  border: 1px solid #ddd;
}

.faq-container .accordion-header {
  font-weight: bold;
}

.faq-container .accordion-button {
  font-weight: bold;

  border: none;
  border-radius: 5px;
}

.faq-container .accordion-button:not(.collapsed) {
  background-color: #ced4da;
}

.faq-container .accordion-button:focus {
  box-shadow: none;
}

@media (min-width: 768px) {
  .faq-container .col-md-6 {
    padding-left: 30px;
    padding-right: 30px;
  }

}

@media (max-width: 768px) {
  .site-navbar {
    height: 222px !important;
    }
}

.custom-container {
  padding: 20px;
  background-color: #f8f9fa;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1199px) {
  .dropdown-menu {
    display: block;
    position: relative;
    float: none;
    width: 100%;
    margin-top: 0;
    box-shadow: none;
  }
  .dropdown-menu .dropdown-item {
    width: 100%;
    text-align: left;
  }
}
.app-store-icon {
display: flex;

}

textarea.form-control {
  height: auto;
}

.apps {
  margin-top: 30px;
}
.chrome {
  max-width: 119px;
  }

.play {
   max-width: 132px;
  }

  .apple {
     max-width: 115px;
    }

.custom-section-title {
  margin-bottom: 20px;
}

.custom-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.custom-description {
  font-size: 1.2rem;
}

.custom-alert {
  font-size: 1.1rem;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}


/* LoggedIn.css */
.gmail-container {
  display: flex;
 min-height: 1000px;
  margin-top: 150px;
}

/* LoggedIn.css */

/* Adjusted sidebar */
.gmail-sidebar {
  background-color: #f1f3f4;
  padding: 20px;
  box-sizing: border-box;
}

.compose-button {
  display: flex;
  align-items: center;
  background-color: #4e319f;
  color: white;
  padding: 8px;
  border-radius: 24px;
  cursor: pointer;
  margin-bottom: 20px;
}

.compose-button span {
  margin-left: 8px;
  font-weight: bold;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #202124;
  cursor: pointer;
  border-radius: 24px 24px 24px 24px;
}

.sidebar-item:hover,
.sidebar-item.active {
  background-color: #212529;
  color: white;
}

.sidebar-item span {
  margin-left: 16px;
}

.sidebar-labels {
  margin-top: 20px;
}

.labels-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5f6368;
  padding: 8px 0;
}

.labels-header .labels-title {
  margin-left: 8px;
  font-size: 14px;
}

.labels-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.labels-list .sidebar-item {
  padding-left: 20px;
}

.inbox-container {
  padding: 20px;
}

.inbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inbox-filters {
  display: flex;
}

.filter-button {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: #5f6368;
  font-size: 13px;
}

.filter-button.active,
.filter-button:hover {

  background-color: #7e808675;

}

.filter-button span {
  margin-left: 8px;
}

.inbox-actions .action-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #5f6368;
  font-size: 18px;
  margin-left: 8px;
}

.email-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  font-size: 13px;
}

.email-item:hover,
.email-item.selected {
  background-color: #f1f3f4;
}

.email-checkbox,
.email-star,
.email-important {
  width: 36px;
  text-align: center;
}

.email-sender {
  width: 200px;
  font-weight: bold;
  color: #202124;
  font-size: 13px;
}

.email-subject {
  flex: 1;
  color: #5f6368;
  font-size: 13px;
}

.email-date {
  width: 100px;
  text-align: right;
  color: #5f6368;
  font-size: 13px;
}


/* Enhanced Gmail Content Styling */
.gmail-content {
  flex: 1;
  background-color: #f5f5f5; /* Light gray background for contrast */
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between child elements */
}

/* Header Section within Gmail Content */
.gmail-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.gmail-content-header h2 {
  font-size: 1.5rem;
  color: #202124;
  margin: 0;
}

.gmail-content-header .action-buttons {
  display: flex;
  gap: 10px;
}

/* Action Buttons Styling */
.gmail-content-header .action-buttons .btn {
  padding: 8px 12px;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.gmail-content-header .action-buttons .btn:hover {
  background-color: #e8f0fe;
  color: #1a73e8;
}

/* Form Fields Styling */
.gmail-content .form-field input,
.gmail-content .form-field .input-text {
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 12px 14px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.gmail-content .form-field input:focus,
.gmail-content .form-field .input-text:focus {
  border-color: #1a73e8;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  outline: none;
}

/* Message Box Styling */
.gmail-content #message {
  min-height: 400px;
  max-height: 500px; /* Optional: Limit max height */
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 12px 14px;
  font-size: 1rem;
  background-color: #ffffff;
  transition: border-color 0.3s, box-shadow 0.3s, height 0.2s;
  overflow: hidden; /* Hide scrollbars */
  resize: none; /* Prevent manual resizing */
}

/* Placeholder for ContentEditable */
.gmail-content #message.empty::before {
  content: attr(data-placeholder);
  color: #6c757d;
  pointer-events: none;
  display: block;
}

/* Buttons Group Styling */
.gmail-content .btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.gmail-content .btn-group .btn {
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
  .gmail-content {
    padding: 15px;
  }

  .gmail-content-header h2 {
    font-size: 1.25rem;
  }

  .gmail-content-header .action-buttons .btn {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .gmail-content .btn-group {
    flex-direction: column;
  }

  .gmail-content .btn-group .btn {
    width: 100%;
  }
}


/* Email content area */
.email-content-area {
  padding: 20px;
  background-color: #fff;
}

.email-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-content-header h2 {
  font-size: 18px;
}

.email-content-actions .btn {
  margin-left: 8px;
  font-size: 13px;
  padding: 6px 10px;
}

.email-settings-dropdown .settings-icon {
  font-size: 16px;
  cursor: pointer;
}

.settings-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  right: 10px;
}

.settings-menu .dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.settings-menu .dropdown-item:hover {
  background-color: #f1f1f1;
}

/* No emails message */
.no-emails {
  text-align: center;
  color: #5f6368;
  margin-top: 50px;
}

/* Pagination controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls .btn {
  margin: 0 10px;
}

/* Google Sign-In button */
.google-signin {
  display: block;
  margin: 20px auto;
}

/* Popup message styling */
.popup {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 100px);
  background-color: #323232;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
}

/* Loading spinner */
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 48px;
  color: #1a73e8;
}

body {
  font-family: 'Roboto', sans-serif;
}

